@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.spinner-container{
   position:absolute;
   left:45vw;
}


.loading-spinner{
      height: 30px;
  width: 30px;
    border: 1px solid #cec9c9; /* Light grey */
  border-top: 1px solid #383636; /* Black */
  border-radius: 50%;
  border-right: none;
  margin: 16px auto;
  animation: spinner 1s linear infinite;
}