header > h4, h2 {
  color: #fff;
    font-size: 2rem;
    padding: 12px;
}


.footer{
  background-color: #fafafa;
}

.h5-footer{
  font-size: 0.9rem
}

.coll > span, a, i{
  font-size: 0.8rem;
}

main{
  min-height: 80vh;
}

header{
  background-image: url('https://rhapsodybibles.org/wp-content/uploads/2019/03/bg-header-rb.jpg');
  background-repeat: repeat no-repeat;
}