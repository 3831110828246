.donation-container form {
   
    width: 100%;
    margin: auto;
    /* align-items: center; */
    margin-top: -3vh;
    max-width: 50%;
    min-width: 50%;
}

.donation-container form input, .donation-container form select, .donation-container form button{
    width:100%;
    height: 37px;
    border-radius: 5px;
    /* outline: none; */
    border: none;
}



.donation-container form input, .donation-container form select{
  background-color: #f2f2f2;
}


.donation-container form input{
    padding-left: 5px;
}

.donation-container form button{
background-color: #098060;
color: white;
}

.donation-container  h1{
    margin-top: 20px!important;
    margin: auto;
    display: block;
    font-size: 20px;
    text-align: center;
}

