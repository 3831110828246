.admin-login-container{
      margin-left:28vw;
       margin-top: 10vh;
}

.admin-login-container input,.admin-login-container button{
      width: 25vw;
      height: 8vh;
      border-radius: 5px;
      border: none;
}

.admin-login-container button{
      background-color: rgb(21, 97, 21);
      color: white;
      border: none;
}

.admin-login-container button:hover{
      opacity: .9;
      transition: all .3s ease-out;
}

.admin-login-container input{
       background: #f9f9f9;
       border: 1px solid black;
}