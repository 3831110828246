/*admin table*/
.admin-dashboard table{
      border-collapse: collapse;
  width: 100%;
}

.admin-dashboard table th, .admin-dashboard table td {
  text-align: left;
  padding: 8px;
}

.admin-dashboard table tr:nth-child(even){background-color: #f2f2f2}

.admin-dashboard table th {
  background-color: rgb(21, 97, 21);
  color: white;
}

.admin-dashboard .download-btn,.admin-dashboard .logout-btn{
width: 10vw;
height: 6vh;
color: white;
  background-color: rgb(21, 97, 21);
  margin-left: 2vw;
  outline: none;
  text-align: center;
  border: none;
  border-radius: 5%;
}

.admin-dashboard button.download-btn:hover,.admin-dashboard button.logout-btn:hover{
 opacity: .9;
 transition: all .3s ease-in;
}

.admin-dashboard button.download-btn a{
  color: white;
}

.admin-dashboard button.download-btn a:hover{
  text-decoration: none;
}

/*table footer*/
.XtableFooter {
  background-color:  white;
  padding: 8px 0px;
  width: 100%;
  font-weight: 500;
  text-align: left;
  font-size: 16px;
  color: #2c3e50;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Xbutton {
  border: none;
  padding: 7px 14px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 4px;
  margin-left: 4px;
}

.XactiveButton {
  color: white;
 background-color: rgb(21, 97, 21);
}

.XinactiveButton {
  color: #2c3e50;
  background: #f9f9f9;
}

